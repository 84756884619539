var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-1 mt-1", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pa-0", attrs: { cols: "8" } }, [
                _vm.projectDrawingObj.isProjectDrawing
                  ? _c(
                      "h3",
                      { staticClass: "pa-1 pl-2" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("message.projects.project")) + ": "
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: "/projects/" + _vm.projectId } },
                          [_vm._v(_vm._s(_vm.projectFullName))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-end pr-5 py-1",
                  attrs: { cols: "4" },
                },
                [
                  _vm.checklistEnable
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          on: {
                            click: function ($event) {
                              return _vm.goChecklist()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("message.checklist.title")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.renderScrollList
        ? _c("scroll-list", {
            key: _vm.reInit,
            attrs: { id: "drawingScrollList", payload: _vm.scrollListPayload },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }